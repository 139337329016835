/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {

        // Properly update the ARIA states on focus (keyboard) and mouse over events
        $('[role="menubar"]').on('focus.aria  click.aria', '[aria-haspopup="true"]', function (ev) {
          //console.log($());
          $(ev.currentTarget).attr('aria-expanded', true);
          $('ul.sub-menu', ev.currentTarget).css('display', 'flex');
        });

        // Properly update the ARIA states on blur (keyboard) and mouse out events
        $('[role="menubar"]').on('blur.aria  mouseleave.aria', '[aria-haspopup="true"]', function (ev) {
          $(ev.currentTarget).attr('aria-expanded', false);
          //$('ul.sub-menu', ev.currentTarget).css('display', 'none');
        });

        //		(function($) {
        function changeFont(fontSize) {
          return function () {
            $('body').css('font-size', fontSize + '%');
            sessionStorage.setItem('fSize', fontSize);
          };
        }

        var normalFont = changeFont(100),
          mediumFont = changeFont(100),
          largeFont = changeFont(135);

        $('.js-font-decrease').on('click', function () {
          normalFont();
        });

        $('.js-font-normal').on('click', function () {
          mediumFont();
        });

        $('.js-font-increase').on('click', function () {
          largeFont();
        });

        if (sessionStorage.length !== 0) {
          $('body').css('font-size', sessionStorage.getItem('fSize') + '%');
        }

        //	})(jQuery);

        //	(function($) { // Begin jQuery
        $(function () { // DOM ready
          // If a link has a dropdown, add sub menu toggle.
          $('.nav-primary .top-nav li a:not(:only-child)').click(function (e) {
            $(this).siblings('.nav-primary .sub-menu').css('display', 'flex');
            // Close one dropdown when selecting another
            $('.nav-primary .sub-menu').not($(this).siblings()).hide();
            e.stopPropagation();
          });
          // Clicking away from dropdown will remove the dropdown class
          $('html').click(function () {
            $('.nav-primary .sub-menu').hide();
          });
        }); // end DOM ready
        //	})(jQuery); // end jQuery


        var header = $(".banner");
        $(window).scroll(function () {
          var scroll = $(window).scrollTop();

          if (scroll >= 132) {
            header.removeClass('banner-lrg').addClass("banner-sml");
          } else {
            header.removeClass("banner-sml").addClass('banner-lrg');
          }
        });


        var maxHeight = 0;

        setTimeout(function () {
          $(".match-height").each(function () {
            if ($(this).height() > maxHeight) {
              maxHeight = $(this).height();
            }
          });

          $(".match-height").height(maxHeight);
        }, 500);


        function toggleIcon(e) {
          $(e.target)
            .prev('.panel-heading')
            .find(".more-less")
            .toggleClass('glyphicon-plus glyphicon-minus');
        }
        $('.panel-group').on('hidden.bs.collapse', toggleIcon);
        $('.panel-group').on('shown.bs.collapse', toggleIcon);

        $('.panel-heading a').click(function () {
          $('.panel-heading').removeClass('active');
          if (!$(this).closest('.panel').find('.panel-collapse').hasClass('in')) {
            $(this).parents('.panel-heading').addClass('active');
          }
        });



        jQuery('img.svg').each(function () {
          var $img = jQuery(this);
          var imgID = $img.attr('id');
          var imgClass = $img.attr('class');
          var imgURL = $img.attr('src');

          jQuery.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
              $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
              $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

          }, 'xml');

        });

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
